<template>
  <div class="pt-5 px-6">
    <div class="flex items-center flex-wrap">
      <h1 class="text-2xl flex-grow">
        <ShoppingOutlined />
        Order electronic device detail
      </h1>
    </div>
  </div>
  <a-divider />
  <div class="px-4">
    <div class="form-detail">
      <div class="col-12 text-center form-title"><strong>Order electronic device detail</strong></div>
      <a-form ref="formRef" :model="form" layout="vertical">
        <div class="flex gap-4">
          <div class="flex-1">
            <a-form-item label="Device name" name="deviceName">
              {{form.deviceName}}
            </a-form-item>
          </div>
        </div>
        <div class="flex gap-4">
          <div class="flex-1">
            <a-form-item label="Type of device" name="quantity" disabled="true">
              <TypeOfDeviceSelectForDetail
                label="Type of devices"
                :selectedIds="form.typeOfDeviceId"
                @update:onSelect="ids => (form.typeOfDeviceId = ids)"
              />
            </a-form-item>
          </div>
          <div class="flex-1">
            <a-form-item label="Quantity" name="quantity">
              {{form.quantity}}
            </a-form-item>
          </div>
          <div class="flex-1">
            <a-form-item label="Date" name="dateAt">
              {{formatDate(form.dateAt)}}
            </a-form-item>
          </div>
          <div class="flex-1">
            <a-form-item label="Expired date" name="expiredAt">
              {{formatDate(form.expiredAt)}}

            </a-form-item>
          </div>
        </div>

        <a-form-item label="User(if any)" name="user">
          {{ form.user }}
        </a-form-item>

        <a-form-item label="Device Information (Link)" name="deviceInformation" class="mt-4">
          {{ form.deviceInformation }}
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import OrderElectronicDeviceFormModal from '@/components/order-electronic-device/OrderElectronicDeviceFormModal.vue'
import TypeOfDeviceById from '@/components/type-of-device/TypeOfDeviceById.vue'
import TypeOfDeviceSelectForDetail from '@/components/type-of-device/TypeOfDeviceSelectForDetail.vue'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'
import { formatDate } from '@/utils/helpers'
import { IOrderElectronicDevice, IUser } from '@/utils/types'
import { DeleteOutlined, FormOutlined, ShoppingOutlined } from '@ant-design/icons-vue'
import { maska } from 'maska'
import moment from 'moment'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    ShoppingOutlined,
    TypeOfDeviceSelectForDetail,
    TypeOfDeviceById,
    OrderElectronicDeviceFormModal,
    FormOutlined,
    DeleteOutlined,
  },
  directives: { maska },
})
export default class OrderElectronicDeviceDetail extends Vue {
  loading = false
  searchQuery = ''

  orderElectroicDevices: IOrderElectronicDevice[] = []

  getId() {
    return this.$route.params?.id
  }

  form: IOrderElectronicDevice = {
    deviceName: '',
    typeOfDeviceId: '',
    deviceInformation: '',
    fireChangeType: '',
    user: '',
    isSendMail: false,
    expiredAt: moment(),
    dateAt: moment(),
    createdAt: 0,
    updatedAt: 0,
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  rawToFormData(rawData: IOrderElectronicDevice) {
    if (rawData.expiredAt) {
      rawData.expiredAt = moment.unix(rawData.expiredAt as number)
    }

    if (rawData.dateAt) {
      rawData.dateAt = moment.unix(rawData.dateAt as number)
    }

    return rawData
  }

  async getOrderElectronicDevice() {
    return await FireOrderElectronicDevice.get(this.getId())
  }

  get orderElectronicDeviceInfo(): IOrderElectronicDevice {
    return this.$store.getters.orderElectronicDevices || {}
  }

  formatDate = formatDate

  async getOrderElectronicDevices() {
    const orderDeviceId = this.getId()
    FireOrderElectronicDevice.on((orderElectronicDevice: IOrderElectronicDevice) => {
      if (orderElectronicDevice.id === orderDeviceId) {
        this.form = orderElectronicDevice
      }
    })
  }

  beforeDestroy() {
    FireOrderElectronicDevice.off()
    this.orderElectroicDevices = []
  }

  created() {
    this.getOrderElectronicDevices()
  }
}
</script>
<style lang="scss">
.form-detail {
  border: solid 1px #ccc;
  padding: 20px;
  width: 60%;
  margin: auto;
}
.form-title{
  border-bottom:solid; font-size:2em;
  margin-bottom:20px
}
</style>
