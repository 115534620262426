
import OrderElectronicDeviceFormModal from '@/components/order-electronic-device/OrderElectronicDeviceFormModal.vue'
import TypeOfDeviceById from '@/components/type-of-device/TypeOfDeviceById.vue'
import TypeOfDeviceSelectForDetail from '@/components/type-of-device/TypeOfDeviceSelectForDetail.vue'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'
import { formatDate } from '@/utils/helpers'
import { IOrderElectronicDevice, IUser } from '@/utils/types'
import { DeleteOutlined, FormOutlined, ShoppingOutlined } from '@ant-design/icons-vue'
import { maska } from 'maska'
import moment from 'moment'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    ShoppingOutlined,
    TypeOfDeviceSelectForDetail,
    TypeOfDeviceById,
    OrderElectronicDeviceFormModal,
    FormOutlined,
    DeleteOutlined,
  },
  directives: { maska },
})
export default class OrderElectronicDeviceDetail extends Vue {
  loading = false
  searchQuery = ''

  orderElectroicDevices: IOrderElectronicDevice[] = []

  getId() {
    return this.$route.params?.id
  }

  form: IOrderElectronicDevice = {
    deviceName: '',
    typeOfDeviceId: '',
    deviceInformation: '',
    fireChangeType: '',
    user: '',
    isSendMail: false,
    expiredAt: moment(),
    dateAt: moment(),
    createdAt: 0,
    updatedAt: 0,
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  rawToFormData(rawData: IOrderElectronicDevice) {
    if (rawData.expiredAt) {
      rawData.expiredAt = moment.unix(rawData.expiredAt as number)
    }

    if (rawData.dateAt) {
      rawData.dateAt = moment.unix(rawData.dateAt as number)
    }

    return rawData
  }

  async getOrderElectronicDevice() {
    return await FireOrderElectronicDevice.get(this.getId())
  }

  get orderElectronicDeviceInfo(): IOrderElectronicDevice {
    return this.$store.getters.orderElectronicDevices || {}
  }

  formatDate = formatDate

  async getOrderElectronicDevices() {
    const orderDeviceId = this.getId()
    FireOrderElectronicDevice.on((orderElectronicDevice: IOrderElectronicDevice) => {
      if (orderElectronicDevice.id === orderDeviceId) {
        this.form = orderElectronicDevice
      }
    })
  }

  beforeDestroy() {
    FireOrderElectronicDevice.off()
    this.orderElectroicDevices = []
  }

  created() {
    this.getOrderElectronicDevices()
  }
}
