<template>
  <div>User profile page</div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IUser } from '@/utils/types'
import { RightCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue'

@Options({
  components: { RightCircleOutlined, CheckCircleOutlined },
  directives: { maska },
})
export default class UserForm extends Vue {
  get userId() {
    return this.$route.params.id
  }

  get user(): IUser {
    return this.$store.state.user.item
  }
}
</script>
